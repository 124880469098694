<template>
  <div class="container-page">
    <div class="card-project" v-if="item">
      <p class="title-inner">
        Матрица проектов –
        <span class="fw500">
          {{ item.name }}
        </span>
      </p>

      <div class="mb-16">
        <ButtonStock title="Экспорт" class="btn-small mr-2" @click="exportWord" />
        <ButtonStock title="Создать запрос ЗА" class="btn-small" @click="createEap" v-if="canCreateEap" />
      </div>

      <div class="card-project__body">
        <div class="card-project__body-row">
          <div class="card-project__body-block">
            <p class="card-project__title main">
              <span>{{ item.name }}</span>
              <span v-if="item.country" class="country">
                {{ item.country.name }}
                <img v-if="item.country.picture" class="flag" :src="item.country.picture" alt="" />
              </span>
            </p>
            <p v-if="item.status" :class="getColor(item.status.id)">{{ item.status ? item.status.name : '' }}</p>
          </div>

          <div class="card-project__container-blocks">
            <div class="card-project__body-block">
              <p class="card-project__title-sub" v-if="item.purpose">Цель проекта:</p>
              <p class="card-project__value" v-if="item.purpose">
                {{ item.purpose ? item.purpose.name : '' }}
              </p>
            </div>

            <div class="card-project__body-block">
              <p class="card-project__title-sub" v-if="item.industry">Отрасль:</p>
              <p class="card-project__value" v-if="item.industry">
                {{ item.industry ? item.industry.name : '' }}
              </p>
            </div>

            <div class="card-project__body-block">
              <p class="card-project__title-sub">Дата внесения информации о статусе проекта</p>
              <p class="card-project__value">
                {{ formatDate(item.dateProjectStatus) }}
              </p>
            </div>

            <div class="card-project__body-block" v-if="item.createdObjectsList">
              <p class="card-project__title-sub">Перечень создаваемых объектов</p>
              <p class="card-project__value">
                {{ item.createdObjectsList }}
              </p>
            </div>

            <div class="card-project__body-block" v-if="item.createdObjectListName">
              <p class="card-project__title-sub">Перечень создаваемых объектов, наименование</p>
              <p class="card-project__value">
                {{ item.createdObjectListName }}
              </p>
            </div>

            <div class="card-project__body-block" v-if="projectListName">
              <p class="card-project__title-sub">Перечень проекта</p>
              <p class="card-project__value">
                {{ projectListName }}
              </p>
            </div>

            <div class="card-project__body-block">
              <p class="card-project__title-sub">Сфера деятельности</p>
              <p class="card-project__value">
                {{ industryName }}
              </p>
            </div>

            <div class="card-project__body-block">
              <p class="card-project__title-sub">Трансграничный проект</p>
              <p class="card-project__value">
                {{ item.isCrossBorderProject ? 'Да' : 'Нет' }}
              </p>
            </div>
          </div>
        </div>

        <!-- Участники -->
        <div class="card-project__body-row">
          <p class="card-project__title">Участники</p>

          <div class="card-project__container-blocks">
            <div class="card-project__body-block">
              <p class="card-project__title-sub">Страны реализации инвестиционного проекта:</p>
              <ul class="card-project__list" v-if="projectCountriesNames?.length > 0">
                <li v-for="item in projectCountriesNames" :key="item">
                  {{ item }}
                </li>
              </ul>
            </div>

            <div class="card-project__body-block" v-if="item.partnerCountry">
              <p class="card-project__title-sub">Cтрана-партнер</p>
              <p class="card-project__value">
                {{ item.partnerCountry.name }}
              </p>
            </div>

            <div class="card-project__body-block">
              <p class="card-project__title-sub">Организация, реализующая проект/SPV:</p>
              <p class="card-project__value">{{ item.organizationImplementingProject }}</p>
            </div>

            <div class="card-project__body-block">
              <p class="card-project__title-sub">Инициатор проекта (головная организация):</p>
              <p class="card-project__value">{{ item.initiator }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.innOrganization">
              <p class="card-project__title-sub">ИНН организации, реализующей проект:</p>
              <p class="card-project__value">{{ item.innOrganization }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.description">
              <p class="card-project__title-sub">Описание проекта:</p>
              <p class="card-project__value">
                {{ item.description }}
              </p>
            </div>
          </div>
        </div>

        <!-- Период реализации -->
        <div class="card-project__body-row" v-if="item?.plannedYearStartInvStage || item?.plannedYearEndInvStage">
          <p class="card-project__title">Период реализации</p>

          <div class="card-project__container-blocks">
            <div class="card-project__body-block" v-if="item?.plannedYearStartInvStage">
              <p class="card-project__title-sub">Начало</p>
              <p class="card-project__value">
                {{ item.plannedYearStartInvStage }}
              </p>
            </div>
            <div class="card-project__body-block" v-if="item.plannedYearEndInvStage">
              <p class="card-project__title-sub">Конец</p>
              <p class="card-project__value">
                {{ item.plannedYearEndInvStage }}
              </p>
            </div>
          </div>
        </div>

        <!-- Параметры -->
        <div class="card-project__body-row">
          <p class="card-project__title">Ход реализации проекта</p>
          <p class="card-project__title">Параметры</p>

          <div class="card-project__container-blocks">
            <div class="card-project__body-block" v-if="item?.totalInvestmentMlrdRubNotDistributed">
              <p class="card-project__title-sub">Общий объем инвестиций, млрд руб. (не распределено)</p>
              <p class="card-project__value">{{ formatCurrency(item.totalInvestmentMlrdRubNotDistributed) }}</p>
            </div>

            <div class="card-project__body-block" v-if="item?.totalInvestmentMlrdRub">
              <p class="card-project__title-sub">Общий объем инвестиций, млрд руб.</p>
              <p class="card-project__value">{{ formatCurrency(item.totalInvestmentMlrdRub) }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.totalInvestmentThousDol">
              <p class="card-project__title-sub">Общий объем инвестиций, тыс. долларов</p>
              <p class="card-project__value">{{ formatCurrency(item.totalInvestmentThousDol) }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.finalBeneficiary">
              <p class="card-project__title-sub">Конечный бенефициар:</p>
              <p class="card-project__value">
                {{ item.finalBeneficiary }}
              </p>
            </div>

            <div class="card-project__body-block" v-if="item.foivCurator">
              <p class="card-project__title-sub">ФОИВ-куратор:</p>
              <p class="card-project__value">
                {{ item.foivCurator.name }}
              </p>
            </div>
          </div>

          <p class="card-project__title">Дополинтельные параметры</p>
          <div class="card-project__container-blocks">
            <div class="card-project__body-block" v-if="item.constantNewJobs">
              <p class="card-project__title-sub">Постоянные новые рабочие места, чел.</p>
              <p class="card-project__value">{{ item.constantNewJobs }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.temporaryNewJobs">
              <p class="card-project__title-sub">Временные новые рабочие места, чел</p>
              <p class="card-project__value">{{ item.temporaryNewJobs }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.regionOfRussiaId">
              <p class="card-project__title-sub">
                Субъект Российской Федерации, в котором будет реализован инвестиционный проект
              </p>
              <p class="card-project__value">{{ russianInvestRegion }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.nsiOkvedSection">
              <p class="card-project__title-sub">Раздел ОКВЭД</p>
              <p class="card-project__value">{{ item.nsiOkvedSection }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.nsiOkvedGroup">
              <p class="card-project__title-sub">Группа ОКВЭД</p>
              <p class="card-project__value">{{ item.nsiOkvedGroup }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.nsiCodeOkved">
              <p class="card-project__title-sub">Курирующий заместитель Министра</p>
              <p class="card-project__value">{{ item.nsiCodeOkved }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.supervisingMinister">
              <p class="card-project__title-sub">Курирующий заместитель Министра</p>
              <p class="card-project__value">{{ item.supervisingMinister }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.departmentId">
              <p class="card-project__title-sub">Ответственный департамент МЭР</p>
              <p class="card-project__value">{{ departmentName }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.minRoleId">
              <p class="card-project__title-sub">Степень участия МЭР в проекте</p>
              <p class="card-project__value">{{ mpkProjectMinRoleName }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.minRoleOther">
              <p class="card-project__title-sub">Иная степень участия МЭР в проекте</p>
              <p class="card-project__value">{{ item.minRoleOther }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.supportMeasureId">
              <p class="card-project__title-sub">Меры поддержки</p>
              <p class="card-project__value">{{ supportMeasureName }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.plannedYearStartOpStage">
              <p class="card-project__title-sub">Плановый год начала эксплуатационной стадии проекта</p>
              <p class="card-project__value">{{ item.plannedYearStartOpStage }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.plannedYearDesignCapacity">
              <p class="card-project__title-sub">Плановый год выхода на проектную мощность</p>
              <p class="card-project__value">{{ item.plannedYearDesignCapacity }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.webSite">
              <p class="card-project__title-sub">Сайт компании</p>
              <p class="card-project__value">
                <a :href="item.webSite" target="_blank" rel="noopener noreferrer">
                  {{ item.webSite }}
                </a>
              </p>
            </div>

            <div class="card-project__body-block" v-if="item.npvValue">
              <p class="card-project__title-sub">Чистая приведенная стоимость (NPV), млрд руб.</p>
              <p class="card-project__value">{{ formatCurrency(item.npvValue) }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.irrValue">
              <p class="card-project__title-sub">Внутренняя норма окупаемость (IRR), %</p>
              <p class="card-project__value">{{ formatCurrency(item.irrValue) }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.implementationPeriodInvestmentPhase">
              <p class="card-project__title-sub">Срок реализации инвестиционной фазы, лет</p>
              <p class="card-project__value">{{ item.implementationPeriodInvestmentPhase }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.projectCategoryId">
              <p class="card-project__title-sub">
                Наименование меры поддержки, в рамках которой ведется учет инвестиционных проектов
              </p>
              <p class="card-project__value">{{ projectCategoryName }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.otherProjectCateogory">
              <p class="card-project__title-sub">
                Наименование иной меры поддержки, в рамках которой ведется учет инвестиционных проектов
              </p>
              <p class="card-project__value">{{ item.otherProjectCateogory }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.centralBankExchangeRate">
              <p class="card-project__title-sub">Курс ЦБ на дату заключения контракта</p>
              <p class="card-project__value">{{ item.centralBankExchangeRate }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.budgetFunding">
              <p class="card-project__title-sub">Бюджетное финансирование, млрд. руб.</p>
              <p class="card-project__value">{{ formatCurrency(item.budgetFunding) }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.federalBudget">
              <p class="card-project__title-sub">
                Финансирование из федерального бюджета (в т.ч. в рамках мер поддержки), млрд, руб.
              </p>
              <p class="card-project__value">{{ formatCurrency(item.federalBudget) }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.regionalBudget">
              <p class="card-project__title-sub">
                Финансирование из региональных бюджетов (в т.ч. в рамках мер поддержки), млрд, руб.
              </p>
              <p class="card-project__value">{{ formatCurrency(item.regionalBudget) }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.municipalBudget">
              <p class="card-project__title-sub">
                Финансирование из муниципальных бюджетов (в т.ч. в рамках мер поддержки), млрд руб.
              </p>
              <p class="card-project__value">{{ formatCurrency(item.municipalBudget) }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.ownBudget">
              <p class="card-project__title-sub">Собственные средства, млрд. руб.</p>
              <p class="card-project__value">{{ formatCurrency(item.ownBudget) }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.raisedBudget">
              <p class="card-project__title-sub">Привлеченные средства, млрд. руб.</p>
              <p class="card-project__value">{{ formatCurrency(item.raisedBudget) }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.bankLoans">
              <p class="card-project__title-sub">Банковские кредиты (в т.ч. в рамках мер поддержки), млрд. руб.</p>
              <p class="card-project__value">{{ formatCurrency(item.bankLoans) }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.otherBorrowedFunds">
              <p class="card-project__title-sub">
                Иные заемные и привлеченные средства (в т.ч. в рамках мер поддержки), млрд. руб.
              </p>
              <p class="card-project__value">{{ formatCurrency(item.otherBorrowedFunds) }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.budgetFundingDollars">
              <p class="card-project__title-sub">Бюджетное финансирование, тыс. долларов</p>
              <p class="card-project__value">{{ formatCurrency(item.budgetFundingDollars) }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.federalBudgetDollars">
              <p class="card-project__title-sub">
                Финансирование из федерального бюджета (в т.ч. в рамках мер поддержки), тыс. долларов
              </p>
              <p class="card-project__value">{{ formatCurrency(item.federalBudgetDollars) }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.regionalBudgetDollars">
              <p class="card-project__title-sub">
                Финансирование из региональных бюджетов (в т.ч. в рамках мер поддержки), тыс. долларов
              </p>
              <p class="card-project__value">{{ formatCurrency(item.regionalBudgetDollars) }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.municipalBudgetDollars">
              <p class="card-project__title-sub">
                Финансирование из муниципальных бюджетов (в т.ч. в рамках мер поддержки), тыс. долларов
              </p>
              <p class="card-project__value">{{ formatCurrency(item.municipalBudgetDollars) }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.ownBudgetDollars">
              <p class="card-project__title-sub">Собственные средства, тыс. долларов</p>
              <p class="card-project__value">{{ formatCurrency(item.ownBudgetDollars) }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.raisedBudgetDollars">
              <p class="card-project__title-sub">Привлеченные средства, тыс. долларов</p>
              <p class="card-project__value">{{ formatCurrency(item.raisedBudgetDollars) }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.bankLoansDollars">
              <p class="card-project__title-sub">Банковские кредиты (в т.ч. в рамках мер поддержки), тыс. долларов</p>
              <p class="card-project__value">{{ formatCurrency(item.bankLoansDollars) }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.otherBorrowedFundsDollars">
              <p class="card-project__title-sub">
                Иные заёмные и привлечённые средства (в т.ч. в рамках мер поддержки), тыс. долларов
              </p>
              <p class="card-project__value">{{ formatCurrency(item.otherBorrowedFundsDollars) }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.volumeOfInvestedFunds">
              <p class="card-project__title-sub">Объем проинвестированных средств, млрд. руб.</p>
              <p class="card-project__value">{{ formatCurrency(item.volumeOfInvestedFunds) }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.dateEnteringInfoOnVolumeInvestments">
              <p class="card-project__title-sub">Дата внесения информации об объеме инвестиций</p>
              <p class="card-project__value">{{ formatDate(item.dateEnteringInfoOnVolumeInvestments) }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.averageAnnualNetProfit">
              <p class="card-project__title-sub">Среднегодовой объем чистой прибыли, млрд. руб</p>
              <p class="card-project__value">{{ formatCurrency(item.averageAnnualNetProfit) }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.averageAnnualRevenueAfterReachingDesignCapacity">
              <p class="card-project__title-sub">
                Среднегодовая выручка после выхода на проектную мощность, млрд. руб.
              </p>
              <p class="card-project__value">
                {{ formatCurrency(item.averageAnnualRevenueAfterReachingDesignCapacity) }}
              </p>
            </div>

            <div class="card-project__body-block" v-if="item.taxPaymentsAtTheInvestmentStage">
              <p class="card-project__title-sub">Налоговые выплаты на инвестиционной стадии, млрд. руб.</p>
              <p class="card-project__value">{{ formatCurrency(item.taxPaymentsAtTheInvestmentStage) }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.taxPaymentsAtTheOperationalStage">
              <p class="card-project__title-sub">Налоговые выплаты на эксплуатационной стадии, млрд. руб.</p>
              <p class="card-project__value">{{ formatCurrency(item.taxPaymentsAtTheOperationalStage) }}</p>
            </div>

            <div
              class="card-project__body-block"
              v-if="item.averageAnnualAmountOfTaxPaymentsAfterReachingDesignCapacity"
            >
              <p class="card-project__title-sub">
                Среднегодовая сумма налоговых выплат после выхода на проектную мощность, млрд. руб.
              </p>
              <p class="card-project__value">
                {{ formatCurrency(item.averageAnnualAmountOfTaxPaymentsAfterReachingDesignCapacity) }}
              </p>
            </div>

            <div class="card-project__body-block" v-if="item.typeEntryId">
              <p class="card-project__title-sub">Тип записи</p>
              <p class="card-project__value">{{ typeEntryName }}</p>
            </div>

            <div class="card-project__body-block" v-if="item.duplicateProjectLink">
              <p class="card-project__title-sub">Ссылка на дублирующий проект</p>
              <p class="card-project__value">
                <a :href="item.duplicateProjectLink" target="_blank" rel="noopener noreferrer">
                  {{ item.duplicateProjectLink }}
                </a>
              </p>
            </div>

            <div class="card-project__body-block" v-if="item.duplicateProjectLinkName">
              <p class="card-project__title-sub">Ссылка на дублирующий проект, наименование</p>
              <p class="card-project__value">
                {{ item.duplicateProjectLinkName }}
              </p>
            </div>

            <div class="card-project__body-block" v-if="item.objectGeotype">
              <p class="card-project__title-sub">Геотип объекта</p>
              <p class="card-project__value">
                {{ item.objectGeotype }}
              </p>
            </div>

            <div class="card-project__body-block" v-if="item.objectLatitude">
              <p class="card-project__title-sub">Широта объекта в координатах WGS-84</p>
              <p class="card-project__value">
                {{ item.objectLatitude }}
              </p>
            </div>

            <div class="card-project__body-block" v-if="item.objectLongitude">
              <p class="card-project__title-sub">Долгота объекта в координатах WGS-84</p>
              <p class="card-project__value">
                {{ item.objectLongitude }}
              </p>
            </div>

            <div class="card-project__body-block" v-if="item.lineCoordinates">
              <p class="card-project__title-sub">Набор координат линии объекта в координатах WGS-84</p>
              <p class="card-project__value">
                {{ item.lineCoordinates }}
              </p>
            </div>

            <div class="card-project__body-block" v-if="item.polygonCoordinates">
              <p class="card-project__title-sub">Набор координат полигона объекта в координатах WGS-84</p>
              <p class="card-project__value">
                {{ item.polygonCoordinates }}
              </p>
            </div>

            <div class="card-project__body-block" v-if="item.catalogNumber">
              <p class="card-project__title-sub">Каталог-источник</p>
              <p class="card-project__value">
                {{ item.catalogNumber }}
              </p>
            </div>

            <div class="card-project__body-block" v-if="item.ehdId">
              <p class="card-project__title-sub">Код во внешнем источнике</p>
              <p class="card-project__value">
                {{ item.ehdId }}
              </p>
            </div>

            <div class="card-project__body-block" v-if="item.signatureDate">
              <p class="card-project__title-sub">Дата подписания</p>
              <p class="card-project__value">
                {{ item.signatureDate?.split(' ')[0] }}
              </p>
            </div>

            <div class="card-project__body-block" v-if="item.createDate">
              <p class="card-project__title-sub">Дата создания</p>
              <p class="card-project__value">
                {{ item.createDate?.split(' ')[0] }}
              </p>
            </div>

            <div class="card-project__body-block" v-if="item.problems">
              <p class="card-project__title-sub">Проблема</p>
              <p class="card-project__value">
                {{ item.problems }}
              </p>
            </div>

            <div class="card-project__body-block" v-if="item.comment">
              <p class="card-project__title-sub">Проблема (комментарий)</p>
              <p class="card-project__value">
                {{ item.comment }}
              </p>
            </div>

            <div class="card-project__body-block" v-if="item.portalMpkUrl">
              <p class="card-project__title-sub">Ссылка на портал МПК</p>
              <p class="card-project__value">
                <a :href="item.portalMpkUrl">
                  {{ item.portalMpkUrl }}
                </a>
              </p>
            </div>

            <div class="card-project__body-block">
              <p class="card-project__title-sub">Рассмотрено ФОИВ</p>
              <p class="card-project__value">{{ item.isFoivReviewed ? 'Да' : 'Нет' }}</p>
            </div>

            <div class="card-project__body-block">
              <p class="card-project__title-sub">Требуется актуализация</p>
              <p class="card-project__value">{{ item.needUpdating ? 'Да' : 'Нет' }}</p>
            </div>

            <div class="card-project__body-block" v-if="isKNR && item.details">
              <p class="card-project__title-sub">Подробнее</p>
              <p class="card-project__value">
                {{ item.details }}
              </p>
            </div>

            <div
              class="card-project__body-block"
              v-if="isKNR && item.discussionEvents && item.discussionEvents.length > 0"
            >
              <p class="card-project__title-sub">Обсуждение на межгосударственных мероприятиях:</p>
              <ul>
                <li v-for="(ev, idx) in item.discussionEvents" :key="idx">
                  {{ formatDate(ev.eventDate) }} - {{ ev.name }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import moment from 'moment';

  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';
  import text from '@/common/mixins/text';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import API_NSI from '@/modules/nsi/api/nsi';

  import API from '../api/index';
  import { MPK_PROJECT_ACTIONS_NAME, MPK_PROJECT_GETTERS_NAME } from '../store/name';

  import { DIRECTIVES_ACTIONS_NAME, DIRECTIVES_GETTERS_NAME } from '@/store/directives';

  export default {
    name: 'ProjectCard',
    components: {
      ButtonStock,
    },
    mixins: [security, text],
    data() {
      return {
        id: this.$route.params.id,
        item: {},
        canCreateEap: false,
        industries: [],
        russianRegions: [],
      };
    },
    created() {
      this.checkPermission(Constants.Permissions.MpkProjectCreateEap).then((response) => {
        this.canCreateEap = response.data;
      });
      this.loadCard();
    },
    computed: {
      isKNR() {
        return this.item && this.item.countryId == Constants.countryKNR;
      },
      ...mapGetters({
        getMpkProjectDepartments: MPK_PROJECT_GETTERS_NAME.getMpkProjectDepartments,
        getMpkProjectMinRoles: MPK_PROJECT_GETTERS_NAME.getMpkProjectMinRoles,
        getMpkListProjects: MPK_PROJECT_GETTERS_NAME.getMpkListProjects,

        getMpkProjectSupportMeasures: MPK_PROJECT_GETTERS_NAME.getMpkProjectSupportMeasures,
        getMpkProjectCategories: MPK_PROJECT_GETTERS_NAME.getMpkProjectCategories,
        getMpkProjectTypeEntries: MPK_PROJECT_GETTERS_NAME.getMpkProjectTypeEntries,
        regionsList: DIRECTIVES_GETTERS_NAME.getRegionsSearchIdList,
      }),
      projectListName() {
        return this.getListItemTextById(this.getMpkListProjects, this.item.projectListId);
      },
      industryName() {
        return this.getListItemTextById(this.industries, this.item.industryId);
      },
      russianInvestRegion() {
        return this.getListItemTextById(this.russianRegions, this.item.regionOfRussiaId);
      },
      departmentName() {
        return this.getListItemTextById(this.getMpkProjectDepartments, this.item.departmentId);
      },
      mpkProjectMinRoleName() {
        return this.getListItemTextById(this.getMpkProjectMinRoles, this.item.minRoleId);
      },
      supportMeasureName() {
        return this.getListItemTextById(this.getMpkProjectSupportMeasures, this.item.supportMeasureId);
      },
      projectCategoryName() {
        return this.getListItemTextById(this.getMpkProjectCategories, this.item.projectCategoryId);
      },
      typeEntryName() {
        return this.getListItemTextById(this.getMpkProjectTypeEntries, this.item.typeEntryId);
      },
      projectCountriesNames() {
        return this.item?.projectCountries?.map?.((entry) => entry.country.name);
      },
    },
    methods: {
      ...mapActions({
        getMpkProjectDepartment: MPK_PROJECT_ACTIONS_NAME.getMpkProjectDepartment,
        getMpkProjectMinRole: MPK_PROJECT_ACTIONS_NAME.getMpkProjectMinRole,
        getMpkProjectList: MPK_PROJECT_ACTIONS_NAME.getMpkProjectList,
        getMpkProjectSupportMeasure: MPK_PROJECT_ACTIONS_NAME.getMpkProjectSupportMeasure,
        getMpkProjectCategoryList: MPK_PROJECT_ACTIONS_NAME.getMpkProjectCategoryList,
        getMpkProjectTypeEntry: MPK_PROJECT_ACTIONS_NAME.getMpkProjectTypeEntry,
        getRegionsSearchId: DIRECTIVES_ACTIONS_NAME.getRegionsSearchId,
      }),
      getListItemTextById(list, id) {
        return list.find((item) => item?.id == id)?.text;
      },
      async loadDictionaries() {
        await Promise.all([
          // Перечень проекта
          this.getMpkProjectList(),
          // регионы России
          (async () => {
            const regResponse = await this.getRegionsSearchId();
            if (regResponse.success) {
              this.russianRegions = this.regionsList.map(({ id, name }) => ({ id, text: name }));
            }
          })(),
          (async () => {
            //отрасли
            this.industries = await API_NSI.search('NsiIndustry', { pageNumber: 1, pageSize: 0 })
              .then((response) => {
                return (
                  response.data?.items.map((o) => {
                    return { text: o.name, id: o.id };
                  }) ?? []
                );
              })
              .catch(() => []);
          })(),
          // ответственный департамент МЭР
          this.getMpkProjectDepartment({ pageNumber: 1, pageSize: 0 }),
          // Степень участия МЭР в проекте
          this.getMpkProjectMinRole(),
          // Меры поддержки
          await this.getMpkProjectSupportMeasure(),
          // Наименование меры поддержки, в рамках которой ведется учет инвестиционных проектов
          await this.getMpkProjectCategoryList(),
          // Тип записи
          await this.getMpkProjectTypeEntry(),
        ]);
      },
      async loadCard() {
        await this.loadDictionaries();
        API.find(this.id).then((response) => {
          this.item = response.data;
          if (!this.item.canView) {
            Constants.alert.fire('Недостаточно прав', 'У вас недостаточно прав для просмотра данных проекта', 'error');
            this.$router.push({ name: 'Projects' });
          } else {
            moment.locale('ru');
            if (this.item.startMonthYear)
              this.item.startMonthYear = moment(this.item.startMonthYear).format('MMMM YYYY');
            if (this.item.endMonthYear) this.item.endMonthYear = moment(this.item.endMonthYear).format('MMMM YYYY');
            if (!this.item.fundingAmounts || this.item.fundingAmounts.length === 0) {
              this.item.fundingAmounts = [];
              this.item.fundingAmounts.push({ year: '', amount: '' });
            } else {
              this.item.fundingAmounts = JSON.parse(this.item.fundingAmount);
            }
          }
        });
      },
      getColor(statusId) {
        if (statusId == 1) return 'implemented';
        if (statusId == 2) return 'realize';
        if (statusId == 3) return 'perspective';
      },
      exportWord() {
        API.exportWord({ id: this.id });
      },
      createEap() {
        this.$router.push({
          name: 'EapAssignments',
          params: { projectId: this.item.id, countryId: this.item.countryId },
        });
      },
      formatCurrency(value) {
        return this.formatNumber(value, 1);
      },
      formatDate(date) {
        return this.$momentFormat(date, 'DD MMMM YYYY');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .card-project {
    &__body {
      background: #ffffff;
      box-shadow: 0px 0px 17px rgba(55, 55, 55, 0.08);
      border-radius: 5px;
      padding: 30px;
      width: 100%;
      font-size: 14px;
      &-row {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px dashed #d7d7d7;
        &:last-of-type {
          padding-bottom: 0px;
          margin-bottom: 0px;
          border-bottom: none;
        }
      }
      &-block {
        margin-bottom: 24px;
      }
    }
    &__title {
      margin-bottom: 16px;
      font-weight: 700;
      font-size: 16px;
      &.main {
        display: flex;
        justify-content: space-between;
        span {
          font-weight: 500;
          display: block;
        }
        .country {
          display: flex;
          align-items: center;
        }
        .flag {
          margin-left: 6px;
          width: 30px;
          height: 20px;
        }
      }
      &-sub {
        margin-bottom: 4px;
        font-weight: 700;
      }
    }
    &__value {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.15px;
    }
    &__list {
      margin-bottom: 16px;
    }
    &__container-blocks {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .card-project__body-block {
        width: 48%;
      }
    }
  }
  .implemented {
    color: #00b3a9;
  }
  .realize {
    color: #0078c8;
  }
  .perspective {
    color: #003990;
  }

  @media (max-width: 767px) {
    .card-project__container-blocks {
      .card-project__body-block {
        width: 100%;
      }
    }
  }
</style>
